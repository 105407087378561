export default {
  "Language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
  "AppName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help Center"])},
  "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "Products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "UserManual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Manual"])},
  "QuickStartGuide": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick Start Guide"])},
  "ReadInstructions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read Instructions / FAQs"])},
  "DirectLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy Share"])},
  "Faqs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQs"])},
  "EmailUs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Us"])},
  "NoResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No results found"])},
  "NoResultsAdj": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try adjusting your search or filter to find what you're looking for."])},
  "CopyShareLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy share link"])},
  "Copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])},
  "FirmwareUpdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmware Update"])}
}