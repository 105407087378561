import { reactive } from 'vue'
const { v4: uuidv4 } = require('uuid');
import { useToast } from "vue-toast-notification";
import 'vue-toast-notification/dist/theme-bootstrap.css';
const { fetch: originalFetch } = window;
const env = process.env.NODE_ENV;

function showTips(message) {
  useToast().open({
    message: message,
    type: 'default',
    position: "top"
  });
}
function xheaders(format = "json") {
  let timestamp = new Date().getTime();
  return {
    'x-isRelease': 'true',
    'x-platform': 'web',
    'x-version': this.version,
    'x-locale': window.navigator.language || window.navigator.browserLanguage,
    'x-timestamp': timestamp,
    'x-deviceId': getDeviceId(),
    'x-token': this.userinfo.token,
    'x-format': format,
  }
}
async function postData(apiUrl, data) {
  let timestamp = new Date().getTime();
  const headers = {
    'x-isRelease': 'true',
    'x-platform': 'web',
    'x-version': this.version,
    'x-locale': window.navigator.language || window.navigator.browserLanguage,
    'x-timestamp': timestamp,
    'x-deviceId': getDeviceId(),
  };
  try {
    window.fetch = async (...args) => {
      let [resource, config] = args;
      let response = await originalFetch(resource, config);
      if (!response.ok) {
        return Promise.reject(response);
      }
      return response;
    };

    const response = await fetch(this.baseApiUrl + apiUrl, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify({
        path: apiUrl,
        body: data
      }),
    }).catch((error) => {
      console.error(error.status);
    });
    return await response.json();
  } catch {
    return {
      code: 500,
      msg: '',
      body: {}
    };
  }
}
function getDeviceId() {
  let xdeviceId = localStorage.getItem("xdeviceId");
  if (!xdeviceId) {
    xdeviceId = uuidv4();
    localStorage.setItem("xdeviceId", xdeviceId);
  }
  return xdeviceId;
}
function unixToTime(val) {
  return new Date(val).toLocaleString('zh-CN', {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",  // 添加小时选项  
    minute: "2-digit",  // 添加分钟选项  
    second: "2-digit"  // 添加秒选项  
  });
}
export const global = reactive({
  version: '1.0.0',
  baseApiUrl: env == "development" ? "https://play.xsonicaudio.com:9092/" : "https://play.xsonicaudio.com:9092/",
  pageSize: 8,
  userinfo: { email: "", token: "" },
  postData,
  showTips,
  unixToTime,
  xheaders
})